export type Product = {
  id: string;
  name: string;
  currency: string;
  price: number;
  quantity: number;
  category?: string;
  sku?: string;
  discount?: number;
  coupon?: string;
};

export type UTMParameters = {
  source: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
};

export type Customer = {
  email: string;
  id?: string;
  name?: string;
  phone?: string;
};

export type PurchaseEvent = {
  orderId: string;
  currency: string;
  value: number;
  products: Product[];
  utm?: UTMParameters;
  customer?: Customer;
  tax?: number;
  shipping?: number;
  coupon?: string;
};

export type AddToCartEvent = {
  currency: string;
  product: Product;
  value: number;
};

export type RemoveFromCartEvent = {
  currency: string;
  product: Product;
  value: number;
};

export type AddPaymentInfoEvent = {
  currency: string;
  products: Product[];
  value: number;
};

export type ViewItemEvent = {
  currency: string;
  products: Product[];
  value: number;
};

export type MetaInit = {
  id: string;
};

export type GoogleInit = {
  ga?: string;
  gtm?: string;
};

export type DebugInit = {
  id: string;
};

export type ProviderInit = MetaInit | GoogleInit | DebugInit;

export interface ProviderInterface {
  init: (data: ProviderInit) => void;
  type: string;
  isValid: () => boolean;
}

export interface ProviderFireable {
  addToCart: (data: AddToCartEvent) => void;
  addPaymentInfo: (data: AddPaymentInfoEvent) => void;
  viewItem?: (data: ViewItemEvent) => void;
  purchase: (data: PurchaseEvent) => void;
}

export const SupportedEvents = {
  ADD_TO_CART: 'addToCart',
  ADD_PAYMENT_INFO: 'addPaymentInfo',
  PURCHASE: 'purchase',
  VIEW_ITEM: 'viewItem',
} as const;

export type SupportedEvent =
  (typeof SupportedEvents)[keyof typeof SupportedEvents];
